import React from "react";

function IconDisliked() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 18 18'
    >
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g fill='#FFF' transform='translate(-7 -7)'>
          <path d='M19.398 15.24a2.039 2.039 0 110-4.078 2.039 2.039 0 010 4.078m1.114 6.288a1.357 1.357 0 01-1.851-.517 3.15 3.15 0 00-2.736-1.605c-1.13 0-2.179.615-2.738 1.605a1.36 1.36 0 01-2.367-1.335 5.87 5.87 0 015.105-2.989 5.869 5.869 0 015.104 2.99 1.359 1.359 0 01-.517 1.85m-7.91-10.365a2.04 2.04 0 11-.001 4.08 2.04 2.04 0 01.001-4.08M16 7a9 9 0 10.001 18.001 9 9 0 000-18.001'></path>
        </g>
      </g>
    </svg>
  );
}

export default IconDisliked;