import React from 'react';
import PropTypes from 'prop-types';
import css from './pop-up.scss';

const PopUp = ({ color = 'white', children }) => (
  <div className={css['pop-up']}>
    <div className={css.container}>
      {children}
    </div>
    <div className={css['triangle-container']}>
      <div
        className={css.triangle}
        style={{
          borderTop: `14px solid ${color}`
        }}
      />
      <div className={css.shadow} />
    </div>
  </div>
);

PopUp.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node
};

export default PopUp;
