import React, { useState, useRef, useEffect } from 'react';
import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useLocalisation } from '../../helpers/i18n/use-localisation';
import css from './comment.scss';
import IconSend from '../../assets/images/icon-send';
import { REQUEST } from '../../helpers/constants';
import Button from '../../components/button/button';

const Comment = ({
  label,
  requestStatus,
  onSend,
  onCancel
}) => {
  const [textValue, setTextValue] = useState('');
  const textareaRef = useRef(null);

  const { i18nUserFeedback } = useLocalisation();

  const scrollIntoView = () => {
    window.requestAnimationFrame(() => {
      textareaRef.current.scrollIntoView(true);
    });
  };

  const handleSend = (str) => {
    let translation;
    if (str === 'Sent') {
      translation = i18nUserFeedback ? i18nUserFeedback._(t`Sent`) : 'Sent';
    } else {
      translation = i18nUserFeedback ? i18nUserFeedback._(t`Send`) : 'Send';
    }
    return translation;
  };

  useEffect(() => {
    // to avoid textarea overlapping by virtual keyboard on android devices
    window.addEventListener('resize', scrollIntoView, false);
    textareaRef.current.focus();
    scrollIntoView();
    return () => window.removeEventListener('resize', scrollIntoView, false);
  }, []);

  return (
    <div className={classNames(css.comment, 'feedback-comment')}>
      <p className={css.title}>{label.toString()}</p>
      <textarea
        data-testid="text-input"
        className={css['text-area']}
        rows="4"
        onChange={e => setTextValue(e.target.value)}
        disabled={requestStatus}
        ref={textareaRef}
      />
      <div className={css.footer}>
        <Button
          name="cancel"
          className={css['text-area-btn']}
          onClick={onCancel}
          isDisabled={!!requestStatus}
        >
          {i18nUserFeedback ? i18nUserFeedback._(t`Cancel`) : 'Cancel'}
        </Button>
        <Button
          name="send"
          className={classNames(css['text-area-btn'])}
          onClick={e => onSend(e, textValue)}
          isDisabled={!textValue.trim() || !!requestStatus}
        >
          <span className={css['btn-text']}>
            {requestStatus === REQUEST.SENT ? handleSend('Sent') : handleSend('Send')}
          </span>
          <IconSend />
        </Button>
      </div>
    </div>
  );
};

Comment.propTypes = {
  label: PropTypes.string.isRequired,
  requestStatus: PropTypes.number.isRequired,
  onSend: PropTypes.func.isRequired,
  onCancel: PropTypes.func
};

export default Comment;
