import { setupI18n } from '@lingui/core';
import queryString from 'querystring';
import { loadLinguiCatalogs } from './generate-catalogs';

// manually set up i18n for jslingui, to be used outside react components
// language should be dynamically loaded
export async function getI18N(baseUrl, locales, suffix, localeOverride) {
  return i18nSetup(baseUrl, locales, suffix, localeOverride);
}

export async function i18nSetup(baseUrl, locales, suffix, localeOverride) {
  const catalogs = await loadLinguiCatalogs(baseUrl, locales);

  // get locale from query parameter, default to en-AU
  const locale = localeOverride || getLocale() || 'en-AU';

  const i18n = setupI18n({
    language: locale,
    catalogs
  });

  // console.log('i18nSetup() - i18n =', JSON.stringify(i18n, null, 2));
  window[`i18n${suffix || ''}`] = i18n;
  window.localisation = Object.assign({}, window.localisation, { [`i18n${suffix || ''}`]: i18n, locale });
  return i18n;
}

export function getLocale() {
  const queryParams = window.location.search;
  const query = queryParams.replace('?', '');
  const params = queryString.parse(query);
  return params.locale;
}
