import * as React from "react";
const IconSendSuccess = (props) => (
  <svg
    id='Layer_1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    x='0px'
    y='0px'
    width='18px'
    height='18px'
    viewBox='0 0 18 18'
    style={{
      enableBackground: "new 0 0 18 18",
    }}
    xmlSpace='preserve'
    {...props}
  >
    <style type='text/css'>
      {"\n    .st0{fill:#FFFFFF;}\n\t.st1{fill:#33C200;}\n  "}
    </style>
    <title>{"icon_send_success"}</title>
    <desc>{"\n    Created with Sketch.\n  "}</desc>
    <path
      id='Path'
      className='st0'
      d='M9,0C4,0,0,4,0,9s4,9,9,9s9-4,9-9S14,0,9,0'
    />
    <path
      id='Stroke-1'
      className='st1'
      d='M11.5,4.6c0.4-0.6,1.2-0.8,1.8-0.5c0.6,0.4,0.9,1.2,0.5,1.8l0,0l-4.7,7.9 c-0.5,0.8-1.5,0.9-2.1,0.2l0,0l-2.7-3c-0.5-0.5-0.4-1.4,0.1-1.8C5,8.8,5.8,8.9,6.3,9.4l0,0L7.8,11L11.5,4.6z'
    />
  </svg>
);
export default IconSendSuccess;
