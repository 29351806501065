import React from "react";

function IconComment() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
    >
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g fill='#FFF' transform='translate(-6 -7)'>
          <path d='M17.293 23.455v4.047l3.662-4.047h1.348a3.276 3.276 0 003.277-3.277v-7.901A3.276 3.276 0 0022.303 9H9.777A3.276 3.276 0 006.5 12.277v7.901a3.276 3.276 0 003.277 3.277h7.516z'></path>
        </g>
      </g>
    </svg>
  );
}

export default IconComment;