import React from "react";

function IconSend() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='19'
      height='17'
      viewBox='0 0 19 17'
    >
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g fill='#FFF'>
          <path d='M15.358 8.5L0 6.492V1A1 1 0 011.404.085l17 7.5a1 1 0 010 1.83l-17 7.5A1 1 0 010 16v-5.493L15.358 8.5z'></path>
        </g>
      </g>
    </svg>
  );
}

export default IconSend;