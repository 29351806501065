import axios from 'axios';
import Bowser from 'bowser';

export const sendFeedback = async ({
  ratingIdentifier,
  itemId,
  feedbackData,
  authToken,
  ratingEndPoint,
  id,
  comment,
  locale
}) => {
  const { browser, os, platform } = Bowser.parse(window.navigator.userAgent);
  try {
    await axios({
      method: 'post',
      url: ratingEndPoint,
      headers: { authToken },
      data: {
        ratingIdentifier,
        itemId,
        data: JSON.stringify({
          locale,
          browserName: browser.name,
          browserVersion: browser.version,
          deviceType: platform.type,
          deviceOs: os.name,
          resolution: `${window.screen.width} X ${window.screen.height}`,
          ...feedbackData
        }),
        rating: id,
        feedback: comment
      }
    });
    return false;
  } catch (error) {
    return error;
  }
};

export const getLocale = async (url) => {
  try {
    const locale = await axios({
      method: 'get',
      url
    });
    if (locale) {
      return locale.data;
    }
    return false;
  } catch (error) {
    return error;
  }
};
