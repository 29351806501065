import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './vote.scss';
import IconSend from '../../assets/images/icon-send';
import IconSendSuccess from '../../assets/images/icon-send-success';
import { REQUEST } from '../../helpers/constants';
import Button from '../../components/button/button';

const Vote = ({
  label,
  requestStatus,
  onSend
}) => (
  <div className={css.vote}>
    <p className={css.label}>
      {label.toString()}
    </p>
    <Button
      name="send"
      className={classNames(css['send-btn'], { [css.sent]: requestStatus === REQUEST.SENT })}
      onClick={onSend}
      isDisabled={!!requestStatus}
    >
      {
        requestStatus === REQUEST.SENT ?
          <IconSendSuccess /> :
          <IconSend />
      }
    </Button>
  </div>
);

Vote.propTypes = {
  label: PropTypes.string.isRequired,
  requestStatus: PropTypes.number.isRequired,
  onSend: PropTypes.func.isRequired
};

export default Vote;
