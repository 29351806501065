import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { I18nProvider } from '@lingui/react';
import { getI18N } from '../../helpers/i18n/get-i18n';
import coreCss from '../../styles/core.scss';
import css from './user-feedback.scss';
import { sendFeedback } from '../../helpers/utils';
import { defaultVoteItemActivation, voteItems } from './user-feedback-constants';
import { REQUEST, FEEDBACK_UI_DELAY } from '../../helpers/constants';
import Button from '../../components/button/button';
import PopUp from '../../components/pop-up/pop-up';
import { useLocalisation } from '../../helpers/i18n/use-localisation';

const UserFeedback = ({
  className,
  voteItemsActivation,
  ratingIdentifier,
  localeCdnBaseUrl,
  locale,
  itemId,
  data: feedbackData,
  authToken,
  ratingEndPoint,
  expanded = true,
  onSuccess = () => { },
  onError = () => { },
  onClick = () => { },
  setActiveItem = () => { }
}) => {
  const [currentVoteItem, setCurrentVoteItem] = useState(null);
  const [requestStatus, setRequestStatus] = useState(REQUEST.DEFAULT);
  const [i18n, setI18n] = useState(null);

  window.localisation = window.localisation || {};
  const voteItemsSequence = voteItemsActivation || defaultVoteItemActivation;

  const { i18nUserFeedback } = useLocalisation();
  const updateActiveItem = (item) => {
    setCurrentVoteItem(item);
    setActiveItem(item);
  };

  const handleSend = async (e, id, comment) => {
    setRequestStatus(REQUEST.SENDING);
    onClick(e);
    const feedbackError = await sendFeedback({
      ratingIdentifier,
      itemId,
      feedbackData,
      authToken,
      ratingEndPoint,
      id,
      comment,
      onError,
      locale
    });
    if (feedbackError) {
      updateActiveItem(null);
      setRequestStatus(REQUEST.DEFAULT);
      onError(feedbackError);
    } else {
      setRequestStatus(REQUEST.SENT);
      setTimeout(() => {
        updateActiveItem(null);
        setRequestStatus(REQUEST.DEFAULT);
        onSuccess();
      }, FEEDBACK_UI_DELAY);
    }
  };

  const handleCancel = (e) => {
    updateActiveItem(null);
    onClick(e);
  };

  const handleSelect = (e, id) => {
    updateActiveItem(id);
    onClick(e);
  };

  useEffect(() => {
    (async () => {
      if (localeCdnBaseUrl && locale) {
        getI18N(localeCdnBaseUrl, [locale], 'UserFeedback')
          .then((international) => {
            setI18n(international);
          });
      }
    })();
  }, [localeCdnBaseUrl, locale]);

  useEffect(() => {
    if (!expanded) {
      updateActiveItem(null);
    }
  });

  return (
    <I18nProvider i18n={i18n}>
      <div
        className={classNames(coreCss.core, css['user-feedback'], className)}
      >
        {
          voteItemsSequence.map((voteItem, index) => {
            const { id, Icon, Component, label, value, color } = voteItems(i18nUserFeedback)[voteItem];
            const isVoteItemActive = expanded && currentVoteItem === id;
            return (
              <div key={id} className={classNames(css[`btn-${index}`], { [css.hide]: !expanded })}>
                {
                  isVoteItemActive &&
                  <PopUp color={color}>
                    <Component
                      label={label}
                      requestStatus={requestStatus}
                      onSend={(e, comment) => handleSend(e, value, comment)}
                      onCancel={handleCancel}
                    />
                  </PopUp>
                }
                <Button
                  name={id}
                  className={classNames(css['icon-btn'], { [css['icon-btn-active']]: isVoteItemActive })}
                  isDisabled={!!requestStatus}
                  onClick={e => handleSelect(e, id)}
                >
                  <Icon />
                </Button>
              </div>
            );
          })
        }
      </div>
    </I18nProvider>
  );
};

UserFeedback.propTypes = {
  className: PropTypes.string,
  voteItemsActivation: PropTypes.array,
  ratingIdentifier: PropTypes.string.isRequired,
  localeCdnBaseUrl: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  data: PropTypes.object,
  authToken: PropTypes.string.isRequired,
  ratingEndPoint: PropTypes.string.isRequired,
  expanded: PropTypes.bool,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onClick: PropTypes.func,
  setActiveItem: PropTypes.func
};

export default UserFeedback;
