import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './button.scss';

const Button = ({
  name,
  className,
  isDisabled,
  onClick,
  children
}) => (
  <button
    type="button"
    name={name}
    className={classNames(css.button, className)}
    disabled={isDisabled}
    onClick={onClick}
  >
    {children}
  </button>
);

Button.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node
};

export default Button;
