import React from "react";

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 18 18'
    >
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g fill='#FFF' transform='translate(-7 -7)'>
          <path d='M16 22.027a5.514 5.514 0 01-5.454-4.71.71.71 0 01.705-.804h9.498a.71.71 0 01.705.804A5.515 5.515 0 0116 22.027m-3.398-10.865a2.038 2.038 0 110 4.077 2.038 2.038 0 010-4.077m6.796 0a2.038 2.038 0 110 4.077 2.038 2.038 0 010-4.077M16 7a9 9 0 100 18 9 9 0 000-18'></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;