import { t } from '@lingui/macro';
import IconLike from '../../assets/images/icon-liked';
import IconDislike from '../../assets/images/icon-disliked';
import IconBroken from '../../assets/images/icon-alert';
import IconComment from '../../assets/images/icon-comment';
import Vote from '../vote/vote';
import Comment from '../comment/comment';

export const colors = {
  primary: '#3477c7',
  secondary: '#00abe8',
  tertiary: '#fd7849',
  quartenary: '#b2e1e3',
  quinary: '#efefef',
  senary: '#d1f3ff',
  septenary: '#5f5f5f'
};

export const voteItems = i18nUserFeedback => ({
  like: {
    id: 'like',
    Icon: IconLike,
    Component: Vote,
    label: i18nUserFeedback ? i18nUserFeedback._(t`I like this!`) : 'I like this!',
    value: 'happy',
    color: colors.tertiary
  },
  dislike: {
    id: 'dislike',
    Icon: IconDislike,
    Component: Vote,
    label: i18nUserFeedback ? i18nUserFeedback._(t`I don’t like this!`) : 'I don’t like this!',
    value: 'unhappy',
    color: colors.tertiary
  },
  broken: {
    id: 'broken',
    Icon: IconBroken,
    Component: Vote,
    label: i18nUserFeedback ? i18nUserFeedback._(t`This is broken ...`) : 'This is broken ...',
    value: 'broken',
    color: colors.tertiary
  },
  comment: {
    id: 'comment',
    Icon: IconComment,
    Component: Comment,
    label: i18nUserFeedback ? i18nUserFeedback._(t`Leave us feedback`) : 'Leave us feedback',
    value: 'undetermined',
    color: colors.quinary
  }
});

export const defaultVoteItemActivation = ['like', 'dislike', 'broken', 'comment'];
