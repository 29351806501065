import React from "react";

function IconAlert() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='19'
      height='18'
      viewBox='0 0 19 18'
    >
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g fill='#FFF' transform='translate(-6 -7)'>
          <path d='M15.056 7.25a.52.52 0 01.888 0l8.979 14.714a.52.52 0 01-.444.791H6.52a.52.52 0 01-.444-.791zm.444 10.652a1.34 1.34 0 100 2.68 1.34 1.34 0 000-2.68zm0-7.297c-.74 0-1.34.6-1.34 1.341v3.91c0 .741.6 1.342 1.34 1.342l.129-.007a1.34 1.34 0 001.212-1.334v-3.91c0-.741-.6-1.342-1.341-1.342z'></path>
        </g>
      </g>
    </svg>
  );
}

export default IconAlert;
